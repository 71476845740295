import { useObjectVal } from 'react-firebase-hooks/database'
import { pollsRef } from 'services/firebase'

export default function usePoll(id) {
  const [value, loading, error] = useObjectVal(pollsRef.child(id))

  return {
    error,
    loading,
    poll: value ? { ...value, id } : null,
  }
}

import { useListVals } from 'react-firebase-hooks/database'
import { getPollMembersRef } from 'services/firebase'

export default function usePollMembers(id) {
  const membersRef = getPollMembersRef(id)
  const [values, loading, error] = useListVals(membersRef)
  const addMember = member => membersRef.push(member)

  return {
    members: values,
    loading,
    error,
    addMember,
  }
}

import React from 'react'
import usePollMembers from 'hooks/usePollMembers'
import PollResponseForm from 'components/modules/poll/PollResponseForm'
import PollResponses from 'components/modules/poll/PollResponses'
import Box from 'components/elements/Box'

export default function PollViewPage({ poll }) {
  const { loading, members, addMember } = usePollMembers(poll.id)

  return (
    <Box inset>
      <PollResponses poll={poll} members={members} />
      <Box style={{ marginTop: '1rem', marginBottom: '2rem' }}>
        <PollResponseForm poll={poll} addMember={addMember} autoAdd={!loading && !members.length} />
      </Box>
    </Box>
  )
}

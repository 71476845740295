import { Layout } from 'antd'
import styled from 'styled-components'

const Header = styled(Layout.Header)`
  height: auto !important;
  padding: 0 !important;
  line-height: normal !important;
`

export default Header

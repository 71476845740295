import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'

const StyledFooter = styled(Layout.Footer)`
  text-align: center;
`
const Link = styled.a`
  color: ${({ theme }) => theme.colors.dim} !important;
  font-size: 0.875rem !important;
`

export default function Footer() {
  return (
    <StyledFooter>
      <Link href="mailto:maketimetoemail@gmail.com">maketimetoemail@gmail.com</Link>
    </StyledFooter>
  )
}

export default {
  colors: {
    brand: {
      normal: '#77A8CE',
      light: '#85bbe5',
    },
    background: '#f0f2f5',
    foreground: '#FFFFFF',
    dim: '#c7c9cc',
  },
}

import React from 'react'
import { Route } from 'react-router-dom'
import usePoll from 'hooks/usePoll'
import { withRouter } from 'react-router-dom'
import { getCategoryBySlug } from 'services/app'
import Box from 'components/elements/Box'
import Page from 'components/modules/app/Page'
import Header from 'components/modules/app/Header'
import Content from 'components/modules/app/Content'
import Footer from 'components/modules/app/Footer'
import PollHeader from 'components/modules/poll/PollHeader'
import PollViewPage from './PollViewPage'

export function PollScreen({ history, match, children }) {
  const selectedCategory = getCategoryBySlug(match.params.category)
  const { poll } = usePoll(match.params.pollId)

  return (
    <Page>
      <Header>
        <PollHeader category={selectedCategory} poll={poll} />
      </Header>
      <Content>
        {poll && (
          <Box>
            <Route
              exact
              path={`/${selectedCategory.slug}/:pollId`}
              render={() => <PollViewPage poll={poll} />}
            />
          </Box>
        )}
      </Content>
      <Footer />
    </Page>
  )
}

export default withRouter(PollScreen)

import { DateTime } from 'luxon'

export function rangeToDates({ from, to }) {
  const fromDate = DateTime.fromJSDate(from)
  const toDate = DateTime.fromJSDate(to)

  const dates = []
  let currentDate = fromDate

  do {
    dates.push(currentDate.startOf('day').toISO())
    currentDate = currentDate.plus({ days: 1 })
  } while (currentDate <= toDate)

  return dates
}

import firebase from 'firebase/app'
import 'firebase/database'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

firebase.initializeApp(config)

const databaseRef = firebase.database().ref()
export const pollsRef = databaseRef.child('polls')

export function getPollMembersRef(pollKey) {
  return pollsRef.child(pollKey).child('members')
}

export function getPollMemberRef(pollKey, memberKey) {
  return pollsRef
    .child(pollKey)
    .child('members')
    .child(memberKey)
}

import React from 'react'
import { get, groupBy, map, orderBy } from 'lodash'
import { List, Typography } from 'antd'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import Box from 'components/elements/Box'

export const DATE_FORMAT_MEDIUM = 'cccc, L/d'

export function formatDay(source) {
  const today = DateTime.local()

  if (source.hasSame(today, 'day')) {
    return 'Today'
  }

  const tomorrow = today.plus({ day: 1 })

  if (source.hasSame(tomorrow, 'day')) {
    return 'Tomorrow'
  }

  const yesterday = today.minus({ day: 1 })

  if (source.hasSame(yesterday, 'day')) {
    return 'Yesterday'
  }

  return source.toFormat(DATE_FORMAT_MEDIUM)
}

function getResultData(members) {
  const allDates = members.reduce((dates, member) => {
    return dates.concat(get(member, 'response.dates', []))
  }, [])

  const dateGroups = groupBy(allDates, date => DateTime.fromISO(date).startOf('day'))

  const results = map(dateGroups, (group, date) => ({
    date: formatDay(DateTime.fromISO(date)),
    isoDate: date,
    votes: group.length,
  }))

  return orderBy(results, ['votes', 'isoDate'], ['desc', 'asc'])
}

const Title = styled(Typography.Title)`
  text-align: center;
`

export function PollResponses({ poll, members }) {
  const resultData = getResultData(members).slice(0, 5)

  const responseData = members.map(member => ({
    name: member.name,
    response: `${get(member, 'response.dates.length', 0)} dates`,
  }))

  if (!resultData.length && !responseData.length) {
    return null
  }

  return (
    <Box inset>
      <Box stack>
        <Title level={4}>Who's in?</Title>
        <List
          dataSource={responseData}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta title={item.name} />
              <div>{item.response}</div>
            </List.Item>
          )}
        />
      </Box>
      <Box stack>
        <Title level={4}>Best days</Title>
        <List
          dataSource={resultData}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta title={item.date} />
              <div>
                {item.votes} vote{item.votes === 1 ? '' : 's'}
              </div>
            </List.Item>
          )}
        />
      </Box>
    </Box>
  )
}

export default PollResponses

import React from 'react'
import copy from 'copy-to-clipboard'
import { Typography, Button, notification } from 'antd'
import styled from 'styled-components'
import Box from 'components/elements/Box'
import { buildUrl } from 'utils/url'
import LogoSrc from 'themes/images/logo-180x180.png'

function doCopy(text) {
  copy(text, {
    debug: true,
    message: 'Press #{key} to copy',
  })

  notification.config({
    placement: 'bottomLeft',
  })

  notification.open({
    message: 'Link copied to clipboard',
  })
}

const Heading = styled.h1`
  white-space: nowrap;
  margin-bottom: 0 !important;
  color: #ffffff !important;
  vertical-align: middle;
`

const HeadingBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Logo = styled(Box)`
  width: 50px;
  height: 50px;
  background: url(${LogoSrc}) no-repeat center center;
  background-size: cover;
`

const LightText = styled(Typography.Text)`
  color: #ffffff !important;

  @media only screen and (max-width: 600px) {
    max-width: 180px;
  }
`

const CopyLink = styled.span`
  color: #ffffff !important;
  text-decoration: underline;
`

export default function PollHeader({ category, poll }) {
  return (
    <Box inset style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box style={{ display: 'flex' }}>
        <Logo inline />
        <HeadingBox inline>
          <Heading>
            {poll && poll.name ? poll.name : `make time to ${category.label.toLowerCase()}`}
          </Heading>
          {poll ? <LightText ellipsis>{buildUrl(poll.category, poll.id)}</LightText> : null}
        </HeadingBox>
      </Box>
      {poll ? (
        <Box inset style={{ textAlign: 'right', paddingRight: '0' }}>
          <Button type="link" size="small" onClick={() => doCopy(buildUrl(poll.category, poll.id))}>
            <CopyLink>Copy link</CopyLink>
          </Button>
        </Box>
      ) : null}
    </Box>
  )
}

import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import PollScreen from 'components/pages/PollScreen'
import PollCreatePage from 'components/pages/PollCreatePage'
import GlobalStyle from './GlobalStyle'
import { ThemeProvider } from 'styled-components'
import theme from 'themes/light'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalStyle />
        <Route exact path="/" component={PollCreatePage} />
        <Route exact path="/:category" component={PollCreatePage} />
        <Route path="/:category/:pollId" component={PollScreen} />
      </Router>
    </ThemeProvider>
  )
}

export default App

import { keyBy } from 'lodash'
import app from 'app.json'

const categoriesBySlug = keyBy(app.categories, 'slug')

export function getCategories() {
  return app.categories
}

export function getCategoryBySlug(slug) {
  return categoriesBySlug[slug]
}

import { Layout } from 'antd'
import styled from 'styled-components'

const Content = styled(Layout.Content)`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  min-height: auto !important;
`

export default Content

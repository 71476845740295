import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Lato:400,900&display=swap');

  html, #root {
    font-family: Lato, sans-serif;
    background-color: ${({ theme }) => theme.colors.background};
  }

  .DayPicker-Day {
    border-radius: 0 !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.colors.brand.normal} !important;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.colors.brand.light} !important;
    color: ${({ theme }) => theme.colors.foreground};
  }
`

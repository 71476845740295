import styled, { css } from 'styled-components'

const Box = styled.div`
  ${({ inset }) => (inset ? 'padding: 0.5rem' : '')};
  ${({ stack }) => (stack ? 'margin-bottom: 0.5rem' : '')};
  ${({ inline }) =>
    inline
      ? css`
          ${({ last }) => (!last ? 'margin-right: 0.5rem' : '')};
          display: inline-block;
        `
      : ''};
  ${({ center }) => (center ? 'text-align: center' : '')};
`

export default Box

import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { DateTime } from 'luxon'
import styled from 'styled-components'
import DayPicker, { DateUtils } from 'react-day-picker'
import Box from 'components/elements/Box'
import { Input, Modal, Button } from 'antd'
import 'react-day-picker/lib/style.css'

const StyledButton = styled(Button)`
  max-width: 400px;
`

export function PollResponseForm({ match, poll, history, addMember, autoAdd }) {
  const [selectedDays, setSelectedDays] = useState([])
  const [calendarOpen, setCalendarOpen] = useState(autoAdd || false)
  const [memberName, setMemberName] = useState(null)
  const [loading, setLoading] = useState(false)
  const disabledDays = []

  useEffect(() => {
    setCalendarOpen(autoAdd)
  }, [autoAdd])

  if (poll.dates && poll.dates.length) {
    disabledDays.push({
      before: DateTime.fromISO(poll.dates[0]).toJSDate(),
      after: DateTime.fromISO(poll.dates[poll.dates.length - 1]).toJSDate(),
    })
  }

  return (
    <Box>
      <Box stack center>
        <StyledButton block size="large" type="primary" onClick={() => setCalendarOpen(true)}>
          New response
        </StyledButton>
      </Box>
      <Box center>
        <StyledButton
          block
          size="large"
          onClick={() => {
            history.push(`/`)
          }}
        >
          New poll
        </StyledButton>
      </Box>

      <Modal
        centered
        title="Select your availability"
        visible={calendarOpen}
        confirmLoading={loading}
        okButtonProps={{ disabled: !memberName }}
        okText={!selectedDays.length && !!memberName ? 'None of these work' : 'Done'}
        onOk={async () => {
          setLoading(true)
          const dates = selectedDays.map(day => DateTime.fromJSDate(day).toISO())
          await addMember({ name: memberName, response: { dates } })
          setMemberName(null)
          setSelectedDays([])
          setLoading(false)
          setCalendarOpen(false)
        }}
        onCancel={() => setCalendarOpen(false)}
      >
        <Box center>
          <DayPicker
            selectedDays={selectedDays}
            disabledDays={disabledDays}
            onDayClick={async (day, selected) => {
              const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day),
              )

              if (selectedIndex !== -1) {
                selectedDays.splice(selectedIndex, 1)
                setSelectedDays([...selectedDays])
              } else {
                setSelectedDays([...selectedDays, day])
              }
            }}
          />
        </Box>
        <Input
          value={memberName}
          placeholder="Enter your name"
          size="large"
          onChange={event => setMemberName(event.target.value)}
        />
      </Modal>
    </Box>
  )
}

export default withRouter(PollResponseForm)

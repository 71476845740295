import React, { useState } from 'react'
import { Button, Typography, Input, Radio, Modal } from 'antd'
import DayPicker, { DateUtils } from 'react-day-picker'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import { withRouter } from 'react-router-dom'
import { getCategoryBySlug } from 'services/app'
import { pollsRef } from 'services/firebase'
import { rangeToDates } from 'utils/date'
import Box from 'components/elements/Box'
import Page from 'components/modules/app/Page'
import Header from 'components/modules/app/Header'
import Content from 'components/modules/app/Content'
import Footer from 'components/modules/app/Footer'
import PollHeader from 'components/modules/poll/PollHeader'
import 'react-day-picker/lib/style.css'

const SubmitBox = styled(Box)`
  margin-top: 1rem;
`

function isSelectingFirstDay(from, to, day) {
  const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
  const isRangeSelected = from && to
  return !from || isBeforeFirstDay || isRangeSelected
}

function rangePresetEnd(value) {
  switch (value) {
    case 'one-week':
      return DateTime.local()
        .plus({ weeks: 1 })
        .toJSDate()
    case 'two-weeks':
      return DateTime.local()
        .plus({ weeks: 2 })
        .toJSDate()
    case 'one-month':
      return DateTime.local()
        .plus({ months: 1 })
        .toJSDate()
    default:
      return null
  }
}

export function PollCreatePage({ match, history }) {
  const selectedCategory = getCategoryBySlug(match.params.category || 'meet')
  const [rangeOption, setRangeOption] = useState('two-weeks')
  const [pollName, setPollName] = useState(selectedCategory.label)
  const [loading, setLoading] = useState(false)
  const [calendarOpen, setCalendarOpen] = useState(false)

  const presetStart = DateTime.local().toJSDate()
  const presetEnd = rangePresetEnd(rangeOption)
  const [range, setRange] = useState({ from: presetStart, to: presetEnd })

  // calendar state
  const [from, setFrom] = useState()
  const [to, setTo] = useState()
  const [enteredTo, setEnteredTo] = useState()

  return (
    <Page>
      <Header>
        <PollHeader
          category={selectedCategory}
          onChange={category => {
            history.replace(`/${category.slug}`)
          }}
        />
      </Header>
      <Content>
        <Box inset>
          <Box stack>
            <Typography.Text>What do you want to do?</Typography.Text>
          </Box>
          <Input
            placeholder={selectedCategory.label}
            value={pollName}
            onChange={event => setPollName(event.target.value)}
          />
        </Box>
        <Box inset>
          <Box stack>
            <Typography.Text>In the next</Typography.Text>
          </Box>
          <Box inline>
            <Radio.Group
              value={rangeOption}
              onChange={event => {
                if (event.target.value !== 'custom') {
                  setRangeOption(event.target.value)

                  const presetStart = DateTime.local().toJSDate()
                  const presetEnd = rangePresetEnd(event.target.value)

                  setRange({ from: presetStart, to: presetEnd })
                }
              }}
            >
              <Radio.Button value="one-week">Week</Radio.Button>
              <Radio.Button value="two-weeks">Two Weeks</Radio.Button>
              <Radio.Button value="one-month">Month</Radio.Button>
              <Radio.Button
                value="custom"
                onClick={event => {
                  setFrom(range.from)
                  setTo(range.to)
                  setEnteredTo(range.to)
                  setCalendarOpen(true)
                }}
              >
                Custom
              </Radio.Button>
            </Radio.Group>
          </Box>
        </Box>
        <SubmitBox inset center>
          <Button
            loading={loading}
            size="large"
            style={{ width: '100%' }}
            type="primary"
            onClick={async () => {
              setLoading(true)
              const ref = await pollsRef.push({
                name: pollName,
                category: selectedCategory.slug,
                dates: rangeToDates(range),
              })
              setLoading(false)
              history.push(`/${selectedCategory.slug}/${ref.key}`)
            }}
          >
            Next
          </Button>
        </SubmitBox>
        <Modal
          centered
          title="Pick a date range"
          visible={calendarOpen}
          onOk={async () => {
            setRangeOption('custom')
            setRange({ from, to })
            setCalendarOpen(false)
          }}
          onCancel={() => setCalendarOpen(false)}
        >
          <Box center>
            <DayPicker
              className="PollDateRange"
              fromMonth={from}
              selectedDays={[from, { from, to: enteredTo }]}
              disabledDays={{ before: from }}
              modifiers={{ start: from, end: enteredTo }}
              onDayClick={day => {
                if (from && to && day >= from && day <= to) {
                  setFrom(null)
                  setTo(null)
                  setEnteredTo(null)
                  return
                }

                if (isSelectingFirstDay(from, to, day)) {
                  setFrom(day)
                  setTo(null)
                  setEnteredTo(null)
                } else {
                  setTo(day)
                  setEnteredTo(day)
                }
              }}
              onDayMouseEnter={day => {
                if (!isSelectingFirstDay(from, to, day)) {
                  setEnteredTo(day)
                }
              }}
            />
          </Box>
        </Modal>
      </Content>
      <Footer />
    </Page>
  )
}

export default withRouter(PollCreatePage)
